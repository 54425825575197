import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">



<path d="M2030 3625 c0 -289 4 -525 8 -525 4 0 29 22 55 48 l47 48 0 477 0
477 -55 0 -55 0 0 -525z"/>
<path d="M3698 3610 l2 -540 -315 0 -315 0 0 -605 0 -605 315 0 315 0 0 -115
0 -115 -1255 2 -1255 3 0 -52 0 -53 1310 0 1310 0 2 176 3 175 65 22 c119 40
198 100 259 196 108 170 119 487 24 689 -53 112 -202 230 -315 248 l-38 7 0
553 0 554 -57 0 -58 0 3 -540z m-10 -845 c59 -18 112 -72 130 -134 15 -48 16
-283 1 -334 -6 -21 -26 -56 -46 -78 -45 -50 -107 -69 -225 -69 l-88 0 0 315 0
315 88 0 c50 0 111 -7 140 -15z"/>
<path d="M1367 2943 l3 -1028 50 -3 50 -3 1 948 c0 521 2 962 2 978 l2 30 138
3 137 3 0 49 0 50 -192 0 -193 0 2 -1027z"/>
<path d="M2520 3920 l0 -50 450 0 450 0 0 50 0 50 -450 0 -450 0 0 -50z"/>
<path d="M2560 3220 c-149 -148 -270 -273 -270 -278 0 -4 24 -30 53 -57 l52
-50 325 327 325 328 -108 0 -108 0 -269 -270z"/>
<path d="M2411 2815 c21 -24 286 -288 298 -297 9 -6 -54 59 -139 145 -85 86
-157 157 -159 157 -2 0 -2 -2 0 -5z"/>
<path d="M2030 2540 l0 -240 -84 0 c-92 0 -96 -3 -96 -66 l0 -34 145 0 145 0
0 248 0 248 -45 42 c-25 23 -50 42 -55 42 -6 0 -10 -91 -10 -240z"/>
<path d="M2760 2466 c0 -5 82 -86 111 -111 13 -10 -5 11 -41 48 -62 64 -70 72
-70 63z"/>
<path d="M2728 2303 c-104 -2 -188 -6 -188 -9 0 -4 21 -26 46 -50 l45 -44 202
0 201 0 -54 55 c-30 30 -57 54 -60 53 -3 -2 -89 -4 -192 -5z"/>
<path d="M3410 1262 c0 -4 4 -12 8 -17 4 -6 14 -28 21 -50 7 -22 32 -94 56
-160 65 -181 66 -163 -2 -168 l-58 -3 68 -2 c44 -1 67 2 67 9 0 8 -94 272
-137 387 -6 13 -23 17 -23 4z"/>
<path d="M3934 1228 l1 -43 -67 -3 -68 -3 0 -154 0 -154 -52 -4 -53 -3 62 -2
62 -2 3 153 3 152 68 3 68 3 -3 46 c-4 60 -25 70 -24 11z"/>
<path d="M4230 1253 c9 -16 25 -59 110 -298 l28 -80 -56 -6 -57 -6 68 -1 c37
-1 67 1 67 4 0 7 -116 324 -135 372 -8 17 -19 32 -25 32 -7 0 -7 -6 0 -17z"/>
<path d="M610 1050 l0 -190 60 0 60 0 0 43 c0 36 5 46 34 69 39 31 36 33 85
-55 l33 -58 126 3 c125 3 126 3 132 28 5 23 11 25 73 28 67 3 68 3 78 -28 11
-29 13 -30 75 -30 l63 0 -34 93 c-35 95 -88 239 -100 270 -6 15 -18 17 -78 15
l-71 -3 -64 -172 c-35 -95 -67 -173 -71 -173 -7 0 -114 168 -119 187 -2 8 27
47 63 88 l67 75 -75 0 -75 0 -69 -81 -68 -81 -3 81 -3 81 -59 0 -60 0 0 -190z
m626 28 c9 -29 19 -59 22 -65 3 -9 -8 -13 -37 -13 -24 0 -41 5 -41 12 0 12 32
118 36 118 1 0 10 -24 20 -52z"/>
<path d="M1504 1226 c-50 -22 -77 -64 -71 -110 8 -57 39 -82 132 -106 90 -23
104 -32 85 -55 -24 -28 -110 -13 -110 20 0 11 -13 15 -55 15 -52 0 -55 -1 -55
-26 0 -67 62 -104 176 -104 120 0 174 40 174 129 0 54 -32 80 -133 105 -68 18
-82 25 -82 41 0 30 69 34 87 6 10 -17 23 -21 65 -21 57 0 62 6 43 54 -15 40
-78 66 -157 66 -37 -1 -82 -7 -99 -14z"/>
<path d="M1810 1050 l0 -190 229 0 229 0 11 30 c10 31 11 31 78 28 62 -3 68
-5 73 -28 5 -23 11 -25 68 -28 36 -2 62 1 62 7 0 5 -30 91 -66 190 l-67 181
-71 0 -71 0 -65 -176 c-51 -138 -66 -170 -68 -145 l-3 31 -110 0 -109 0 0 30
0 30 90 0 90 0 0 45 0 45 -91 0 c-88 0 -90 1 -87 23 3 21 8 22 106 25 l102 3
0 44 0 45 -165 0 -165 0 0 -190z m575 -13 l7 -37 -41 0 c-28 0 -39 4 -36 13 2
6 11 37 21 67 l17 55 13 -30 c7 -17 15 -47 19 -68z"/>
<path d="M3072 1203 c46 -52 49 -185 6 -249 -38 -58 -85 -76 -223 -84 l-120
-7 102 -1 c56 -1 125 3 153 9 94 20 140 84 140 196 0 75 -32 153 -63 153 -6 0
-3 -8 5 -17z"/>
<path d="M2856 1182 c-10 -10 -6 -211 4 -217 6 -4 10 32 10 99 l0 106 37 0
c20 0 34 2 32 4 -7 7 -78 14 -83 8z"/>
<path d="M3588 1173 c17 -2 47 -2 65 0 17 2 3 4 -33 4 -36 0 -50 -2 -32 -4z"/>
<path d="M3307 1100 c-20 -69 -21 -80 -8 -80 10 0 41 95 41 124 0 36 -18 12
-33 -44z"/>
<path d="M4121 1094 c-12 -40 -17 -72 -11 -78 5 -5 17 20 29 60 14 42 18 71
12 77 -6 6 -17 -16 -30 -59z"/>
<path d="M3247 901 c-6 -29 -9 -30 -64 -34 l-58 -3 68 -2 c67 -2 68 -2 78 28
10 28 14 29 77 33 l67 3 -81 2 -81 2 -6 -29z"/>
<path d="M4062 901 c-10 -27 -15 -30 -64 -34 -51 -3 -51 -3 14 -5 66 -2 66 -2
73 28 6 29 8 29 78 33 l72 3 -82 2 -81 2 -10 -29z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
